exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-fisim-js": () => import("./../../../src/pages/app/fisim.js" /* webpackChunkName: "component---src-pages-app-fisim-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fisim-js": () => import("./../../../src/pages/fisim.js" /* webpackChunkName: "component---src-pages-fisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-langbot-tsx": () => import("./../../../src/pages/langbot.tsx" /* webpackChunkName: "component---src-pages-langbot-tsx" */),
  "component---src-pages-mediation-assist-tsx": () => import("./../../../src/pages/mediation-assist.tsx" /* webpackChunkName: "component---src-pages-mediation-assist-tsx" */),
  "component---src-pages-message-sent-js": () => import("./../../../src/pages/message-sent.js" /* webpackChunkName: "component---src-pages-message-sent-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-sales-objection-handling-tsx": () => import("./../../../src/pages/sales-objection-handling.tsx" /* webpackChunkName: "component---src-pages-sales-objection-handling-tsx" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

